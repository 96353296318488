<template>
  <v-content>
    <div class="staticCarouselPage">
      <v-img :src="`${imageUpload}/basvuru-tarihi-2024`" loading="lazy"/>
    </div>
    <div class="block">
      <v-container>
        <h2 class="text-center">Duyuru</h2>
        <h3 class="text-center">Güncellenen Başvuru Tarihleri</h3>
        <ul class="section-list">
          <li>Başvuru süreci: 18 Eylül 2023 - 26 Şubat 2024</li>
          <li>Proje değerlendirme kurulunun projeleri ön incelemeden geçirmesi: 5 Mart 2024</li>
          <li>Proje değerledirme kurulunun projeleri nihai değerlendirmesi ve finalislerin ilanı: 11 Mart 2024</li>
        </ul>
      </v-container>
    </div>
    <div class="block">
      <v-container>
        <ProjectPartners items="items"/>
      </v-container>
    </div>
  </v-content>
</template>

<script>
import ProjectPartners from '../components/ProjectPartners.vue'

export default {
  name: "Announcement",
  components: {ProjectPartners},
  data() {
    return {
      items: [
        {
          id: 1,
          src: `${process.env.VUE_APP_IMAGE_UPLOAD}/partners/tekder-logo.png`,
          name: 'Tekder İstanbul',
        },
        {
          id: 2,
          src: `${process.env.VUE_APP_IMAGE_UPLOAD}/partners/il-milli-egitim.jpg`,
          name: 'İstanbul İl Milli Eğitim Müdürlüğü',
        }
      ],
      imageUpload: process.env.VUE_APP_IMAGE_UPLOAD,
    };
  }
};
</script>
<template>
    <v-content>
      <div class="staticCarouselPage">
        <v-img :src="staticData.backgroundPhoto"  loading="lazy"/>
      </div>
      <div class="block" v-show="!(staticData.id === '2022')">
        <h2 class="text-center"> Bir Fikir Bir Proje </h2>
        <v-container>
          <p v-html="staticData.content" ></p>
        </v-container>
        <v-container>
          <h3 class="text-center">Ödüller</h3>
          <ul v-for="(prize, i) in staticData.prize"  :key="prize" class="prizeCss">
            <li>{{i + 1}}. En İyi Proje: {{prize}}</li>
          </ul>
        </v-container>
        <v-container>
          <template>
            <v-card
              class="my-custom-card"
              v-for="item in staticData.items"
              :key="item.id"
            >
            <v-card-title>{{item.proje}}</v-card-title>
              <v-img
                class="white--text align-end"
                height="400"
                :src="item.src"
                loading="lazy"
              >
              </v-img>
          
              <v-card-subtitle class="pb-0">
               {{item.school}}
              </v-card-subtitle>
          
              <v-card-subtitle class="pb-0">
                Öğrenciler
               </v-card-subtitle>
              <v-card-text class="text--primary">
                <ul >
                  <li v-for="student in item.students" :key="student">{{student}}</li>
                </ul>
              </v-card-text>

              <v-card-subtitle class="pb-0">
                Danışmanlar
               </v-card-subtitle>
              <v-card-text class="text--primary">
                <ul >
                  <li v-for="consultant in item.consultants" :key="consultant">{{consultant}}</li>
                </ul>
              </v-card-text>
            </v-card>
          </template>
        </v-container>
      </div>
      <div class="block" v-show="!(staticData.id === '2022')">
        <v-container>
            <Gallery :items="staticData.otherPhotos"/>            
        </v-container>
      </div>
    </v-content>
  </template>
  
  <script>
import Gallery from './Gallery.vue';
import db from '../lib/db.js'

  export default {
    name: "BeforePrograms",
    components: { Gallery },
    data() {
        return {
          staticData: []

        };
    },
  created() {
    var pathArray = window.location.href.slice(-4)
    this.staticData = db.filter(data => data.id === pathArray)[0];

  },
};
  </script>
<style lang="scss">
.prizeCss {
  display: flex !important;
  align-items: center !important;
  flex-direction: column !important;
}

.my-custom-card {
  max-width: 800px; /* Maksimum genişlik değerini belirleyebilirsiniz */
  width: 100%; /* Genişlik ekranın tamamına sığacak şekilde ayarlanır */
  height: auto; /* Yükseklik otomatik olarak ayarlanır */
  margin: 0 auto; /* Resmi yatayda merkezlemek için */
  margin-top: 5px;
  /* İhtiyaca göre diğer özelleştirmeleri ekleyebilirsiniz */
}

</style>
<template>
  <div class="carouselPageBlock">
    <v-carousel hide-delimiters height="auto" cycle>
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :to="item.to"
      >
       <v-sheet
        height="100%"
        tile
       >
        <v-row
          class="fill-height"
          align="center"
          justify="center"
        >
          <v-img :src="item.src"  max-height="1000px" loading="lazy" contain eager/>
        </v-row>
      </v-sheet>  
        <v-row class="title fill-height hidden-xs-only" align="center" justify="center">{{ item.title }}</v-row>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "CarouselPage",
  data() {
    return {
      items: [
        {
          src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/kapak-fotoğrafı`,
          title: "Ödül Töreni",
          to: "/odul-toreni-2024"
        },
        {
          src: `${process.env.VUE_APP_IMAGE_UPLOAD}/program-akisi-2024-kapak`,
          title: "Program Akışı Yayınlandı",
          to: "/program-akısı-2024"
        },
        {
          src: `${process.env.VUE_APP_IMAGE_UPLOAD}/finalistler-2024`,
          title: "Başvuru Tarihleri Güncellenmiştir",
          to: "/finalist-ilani/2024"
        }, {
          src: `${process.env.VUE_APP_IMAGE_UPLOAD}/basvuru-tarihi-2024`,
          title: "Başvuru Tarihleri Güncellenmiştir",
          to: "/duyuru"
        },
        {
          src: `${process.env.VUE_APP_IMAGE_UPLOAD}/twitter-kapak.jpg`,
          title: "Bir Fikir Bir Proje 2023",
          to: "/birfikirbirproje/2023"
        },
        // {
        //   src: `${process.env.VUE_APP_IMAGE_UPLOAD}/twitter-kapak.jpg`,
        //   title: "Bir Fikir Bir Proje 2023",
        //   to: "/birfikirbirproje/2023"
        // }
      ]
    };
  }
};
</script>

<style lang="scss">
.carouselPageBlock {
  .theme--light.v-sheet {
    background-color: #eeee !important;
  }
}

</style>
<template>
  <v-main>
    <CarouselPage />
    <Gallery :items="items" :title="title"/>
    <ProjectPartners/>
    <Partners/>
  </v-main>
</template>

<script>
import CarouselPage from "../components/CarouselPage";
import Gallery from "../components/Gallery";
import Partners from "../components/Partners";
import ProjectPartners from "../components/ProjectPartners.vue";

export default {
  name: "Home",

  components: {
    CarouselPage,
    Gallery,
    Partners,
    ProjectPartners
  },
  data() {
    return {
      items: [
        {
                id: 1,
                src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2019/other/img-1.jpg`,
            },
            {
                id: 2,
                src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2018/other/img4.jpg`
            },
            {
                id: 3,
                src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2019/other/img-3.jpg`,
            },
            {
                id: 4,
                src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2017/other/img-4-2.jpg`,
            },
            {
                id: 5,
                src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2017/other/img-5-2.jpg`,
            },
            {
                id: 6,
                src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2019/other/img-6.jpg`,
            },
            {
                id: 7,
                src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2018/other/img7.jpg`
            },
            {
                id: 8,
                src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2019/other/img-8.jpg`,
            },
            {
                id: 9,
                src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2019/other/img-9.jpg`,
            }
      ],
      title: "Önceki Yarışmalarımızdan"
    }
  },
};
</script>
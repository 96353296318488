<template>
  <v-content>
    <div class="staticCarouselPage mt-5 mb-5" >
      <v-img :src="`${imageUpload}/program-akısı-2024`" loading="lazy"/>
    </div>
  </v-content>
</template>

<script>

export default {
  name: "FinalistAnnouncement",
  data() {
    return {
      imageUpload: process.env.VUE_APP_IMAGE_UPLOAD,
    };
  }
};
</script>

<template>
  <div class="award-winner">
    <h2>Ödül Alan Takımlar</h2>
    <div class="winner-section">
      <h3>Fikir Yarışması</h3>
      <div class="winner-list">
        <ul>
          <li v-for="(team, index) in fikirYarismasi" :key="index">
            <span>{{ team.sira }}.</span>
            <span>{{ team.okul }} - </span>
            <span>{{ team.proje }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="winner-section">
      <h3>Fikir Mansiyon</h3>
      <div class="winner-list">
        <ul>
          <li v-for="(team, index) in fikirMansiyon" :key="index">
            <span>{{ team.okul }} - </span>
            <span>{{ team.proje }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="winner-section">
      <h3>Proje Yarışması</h3>
      <div class="winner-list">
        <ul>
          <li v-for="(team, index) in projeYarismasi" :key="index">
            <span>{{ team.sira }}.</span>
            <span>{{ team.okul }} - </span>
            <span>{{ team.proje }}</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="winner-section">
      <h3>Proje Mansiyon</h3>
      <div class="winner-list">
        <ul>
          <li v-for="(team, index) in projeMansiyon" :key="index">
            <span>{{ team.okul }} - </span>
            <span>{{ team.proje }}</span>
          </li>
        </ul>
      </div>
    </div>
    <p>Ödül alan öğrencilerimizi tebrik eder, yarışmaya katılan bütün öğrencilerimizi göstermiş oldukları özveriden ötürü yürekten kutlarız.</p>
    <div class="gallery">
      <h2>Galeri</h2>
      <div class="gallery-images">
        <img v-for="(image, index) in galleryImages" :key="index" :src="image.src" :alt="'Galeri Görseli ' + image.id">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AwardWinner',
  data() {
    return {
      fikirYarismasi: [
        { sira: "1", okul: "Avcılar MTAL", proje: "Alzheimer Erken Tanı Kiti Olarak Giyilebilir Teknoloji" },
        { sira: "2", okul: "Pendik Türk Telekom MTAL", proje: "Yeşil Dost" },
        { sira: "3", okul: "İsov Dinçkök MTAL", proje: "Öğrenci Takip Sistemi" }
      ],
      projeYarismasi: [
        { sira: "1", okul: "Baykar Milli Teknoloji Lisesi", proje: "Kendinden Isıtmalı Termos" },
        { sira: "2", okul: "Baykar Milli Teknoloji Lisesi", proje: "Best Recylist" },
        { sira: "3", okul: "Baykar Milli Teknoloji Lisesi", proje: "Senin Kokun" }
      ],
      projeMansiyon: [
        { okul: "Ayhan Arı Borsa İstanbul MTAL", proje: "Güneş Panellerinde Verim Arttırma" },
        { okul: "İsov Dinçkök MTAL", proje: "Öğrenci Takip Sistemi" }
      ],
      fikirMansiyon: [
        { okul: "Baykar Milli Teknoloji Lisesi", proje: "Best Recylist" },
        { okul: "Baykar Milli Teknoloji Lisesi", proje: "Senin Kokun" }
      ],
      galleryImages: [
        {  id: 1, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/proje-odulu-birinci`},
        {  id: 2, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/fikir-odulu-birinci`},
        {  id: 3, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/proje-odulu-ikinci`},
        {  id: 4, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/fikir-dalında-ikinci`},
        {  id: 5, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/proje-odulu-ucuncu`},
        {  id: 6, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/fikir-dalinda-ucuncu`},
        {  id: 7, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/mansiyon-odulu`},
        {  id: 8, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/mansiyon-2`},
        {  id: 9, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/sube-baskani`},
        {  id: 10, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/seyirci`},
        {  id:11, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/kuran-tilaveti`},
        {  id: 12, src: `${process.env.VUE_APP_IMAGE_UPLOAD}/2023/ödül-töreni/toplu-fotograf`}
      ]
    };
  }
};
</script>

<style scoped>
.award-winner {
  margin: 0 auto;
  max-width: 800px;
  text-align: center;
}

.winner-section {
  margin-bottom: 20px;
}

.winner-list ul {
  list-style-type: none;
  padding: 0;
}

.winner-list li {
  margin-bottom: 10px;
}

.winner-list span {
  margin-right: 10px;
}

.gallery-images img {
  max-width: 100%;
  margin-bottom: 20px; /* Boşluk ekleyelim */
}
</style>

<template>
  <v-content>
    <div class="page-container">
      <div class="header">
        <v-img :src="`${imageUpload}/2023/1f1p-2023.jpeg`"  class="responsive-image" max-height="1000" loading="lazy" cover />
      </div>
      <div class="content">
        <a href="https://forms.gle/xdVqLqXk3eKveQ1X8" target="_blank" class="application-button">
          Başvur
        </a>
        <section>
          <h1 class="section-title">Program</h1>
<!--          <ul v-if="!pathArray" class="section-list">-->
<!--            <li>Başvuru süreci: 09 Ocak 2023 - 05 Mart 2023</li>-->
<!--            <li>Finale kalan takımların duyurulması: 26 Mart 2023</li>-->
<!--            <li>Finale kalan takımların proje sunum ve görsellerinin web sitesine yüklenmesi için son tarih 30 Nisan 2023</li>-->
<!--            <li>Final programı ve ödül töreni tarihi Tekder ve İstanbul Milli Eğitim Müdürlüğü tarafından daha sonra belirlenecektir.</li>-->
<!--          </ul>-->
          <ul class="section-list">
            <li>Başvuru süreci: 18 Eylül 2023 - 26 Şubat 2024</li>
            <li>Proje değerlendirme kurulunun projeleri ön incelemeden geçirmesi: 5 Mart 2024</li>
            <li>Proje değerledirme kurulunun projeleri nihai değerlendirmesi ve finalislerin ilanı: 11 Mart 2024</li>
          </ul>
        </section>
        <hr class="section-divider" />
        <section>
          <h1 class="section-title">Başvuru Koşulları</h1>
          <ul class="section-list">
            <li>İstanbul ilindeki mesleki ve teknik liselerin 9., 10., 11. ve 12. sınıflarında okuyan tüm öğrenciler kayıtlı olduğu okulu belgelemek koşuluyla yarışmaya katılabilir.</li>
            <li>Bir projede en fazla 3 öğrenci yer alabilir.</li>
            <li>Her takımda bir danışman teknik öğretmen bulunabilir.</li>
            <li>Başvurular www.birfikirbirproje.org web sitesinde ilan edilen online form doldurularak yapılır.</li>
            <li>Finale kalan her bir proje için A1 ya da A2 boyutunda olacak şekilde poster hazırlanır.</li>
            <li>Finale kalan her bir projenin model ve/veya prototipi hazırlanmalıdır.</li>
            <li>Proje dalında finale kalan takımlara 3000₺ değerinde malzeme desteği verilecektir</li>
            <li>Katılımcılar, dereceye giren ya da "yayınlanmaya değer bulunan" projenin TEKDER tarafından kullanılmasına süresiz olarak kullanım hakkını verdiğini peşinen kabul ettiğini ve buna bağlı olarak gerek “Fikir ve Sanat Eserleri Kanunu, gerekse diğer ilgili mevzuat gereğince yarışmaya gönderdiği eserinin çoğaltma, işlenme, yayma, temsil, umuma iletim, faydalanma ve bunun gibi umuma arzla ilgili bilumum telif haklarını TEKDER’e verdiğini kabul eder.</li>
            <li>Yayımlanan eserlere ayrıca telif ücreti ödenmez.</li>
          </ul>
        </section>
        <hr class="section-divider" />
        <section>
          <h1 class="section-title">Fikir Ödülleri</h1>
          <ul class="section-list">
            <li>1.Proje: 5000₺</li>
            <li>2.Proje: 4000₺</li>
            <li>3.Proje: 3000₺</li>
          </ul>
        </section>
        <hr class="section-divider" />
        <section>
          <h1 class="section-title">Uygulama Ödülleri</h1>
          <ul class="section-list">
            <li>1.Proje: 10000₺</li>
            <li>2.Proje: 8000₺</li>
            <li>3.Proje  6000₺</li>
          </ul>
        </section>
        <hr class="section-divider" />
        <section>
          <h1 class="section-title">İletişim</h1>
          <ul class="section-list">
            <li>Adres: Karagümrük Mahallesi TOKİ Konutları, muhtar Muhittin sokak, No: 14, C3-98 Blok, Daire: 3, Sulukule/Fatih/İstanbul</li>
            <li>Telefon: +90 212 532 0375</li>
            <li>Fax: +90 212 532 0375</li>
            <li>E-Posta: iletisim@tekderistanbul.org</li>
          </ul>
        </section>
      </div>
    </div>
  </v-content>
</template>

<script>
// import db from "@/lib/db";

export default {
  name: "CurrentPrograms",
  data() {
    return {
      imageUpload: process.env.VUE_APP_IMAGE_UPLOAD,
    };
  }
};
</script>

<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  background-color: #f4f4f4; /* Hafif gri arka plan rengi */
  border-radius: 10px; /* Köşeleri yuvarlatma */
}

.header {
  width: 100%;
  max-height: 1000px;
  overflow: hidden;
  border-radius: 10px 10px 0 0; /* Köşeleri yuvarlatma (üst köşeler) */
}

.header img {
  max-height: 1000px;
  width: 100%;
  object-fit: cover;
  margin-bottom: 20px;
}

.responsive-image {
  width: 100%;
  height: auto;
}

.content {
  max-width: 800px;
}

.section-title {
  font-size: 24px;
  margin-top: 20px;
  margin-bottom: 10px;
}

.section-list {
  list-style-type: none;
  padding: 0;
}

.section-list li {
  margin-bottom: 10px;
}

.section-divider {
  margin: 20px 0;
  border: none;
  border-top: 2px solid #ccc;
  width: 100%;
}

.application-button {
  display: inline-block;
  margin-top: 10px;
  padding: 10px 20px;
  background-color: #007bff; /* Butonun arka plan rengi */
  color: #fff; /* Buton metin rengi */
  font-size: 18px;
  text-decoration: none;
  border-radius: 5px; /* Köşeleri yuvarlatma */
  transition: background-color 0.2s;
}

.application-button:hover {
  background-color: #0056b3; /* Hover durumunda arka plan rengi */
}

.application-button:active {
  background-color: #003f80; /* Tıklanınca arka plan rengi */
}

</style>

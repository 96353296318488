<template>
  <v-content>
    <div class="staticCarouselPage">
      <v-img :src="`${imageUpload}/finalistler-2024`" loading="lazy"/>
    </div>
    <div class="block">
      <v-container>
        <h2 class="text-center">Duyuru</h2>
        <h3>Değerli katılımcılar,</h3>
        <p>2023-2024 yılı 1 Fikir 1 Proje yarışması başvuru ve değerlendirme süreci tamamlanmış bulunmaktadır. Alanında uzman ekiplerce birden fazla değerlendirme kriteri sonucunda fikir ve proje uygulama alanlarında ilk 20’ye giren finalist takımların proje ve okul bilgileri aşağıda belirtilmektedir. </p>
        <p>Final programı 2 Mayıs 2024 tarihinde saat 09:00'da Esenler Kadir Topbaş Kültür Merkezi'nde gerçekleşecektir.</p>
        <p>Başvuru esnasında hem fikir hem de proje olarak başvuru yapanlar her iki dal içerisinde ayrıca değerlendirilmiştir. Bazı takımlar hem proje hem de fikir alanında finale kalarak iki kategoride de finalist olarak yarışmaya hak kazanmışlardır. </p>
        <p>Proje uygulama kategorisinde finale kalan takımlara 3000 TL ye kadar malzeme desteği verilecektir. Malzeme desteğine başvuracak takımlar için sitemizde malzeme destek başvuru formu paylaşılacaktır. Finale kalan tüm takımlarımızdan final günü A3 boyutlarında bir poster ve bir sunum yapması beklenilmektedir. Sunum taslağı ayrıca tüm finalist takımların başvuru sırasında paylaştıkları email adreslerine gönderilecektir. </p>
        <p>Başvuru yapan tüm takım ve okullarımıza teşekkür eder finale kalan takımlarımıza başarılar dileriz.</p>
        <h3 class="text-left">Fikir Finalistleri</h3>
        <!-- Newly added section -->
        <div class="finalists-list">
          <ul>
            <li v-for="finalist in finalists" :key="finalist.id">
              {{ finalist.projectName }} - {{ finalist.school }}
            </li>
          </ul>
        </div>
        <br/>
        <h3 class="text-left">Proje Finalistleri</h3>
        <div class="finalists-list">
          <ul>
            <li v-for="finalist in projectFinalists" :key="finalist.id">
              {{ finalist.projectName }} - {{ finalist.school }}
            </li>
          </ul>
        </div>
        <!-- End of newly added section -->
      </v-container>
    </div>
    <div class="block">
      <v-container>
        <ProjectPartners :items="items"/>
      </v-container>
    </div>
  </v-content>
</template>

<script>
import ProjectPartners from '../components/ProjectPartners.vue'

export default {
  name: "FinalistAnnouncement",
  components: { ProjectPartners },
  data() {
    return {
      items: [
        {
          id: 1,
          src: `${process.env.VUE_APP_IMAGE_UPLOAD}/partners/tekder-logo.png`,
          name: 'Tekder İstanbul',
        },
        {
          id: 2,
          src: `${process.env.VUE_APP_IMAGE_UPLOAD}/partners/il-milli-egitim.jpg`,
          name: 'İstanbul İl Milli Eğitim Müdürlüğü',
        }
      ],
      imageUpload: process.env.VUE_APP_IMAGE_UPLOAD,
      finalists: [
        { id: 1, projectName: 'Akıllı Şantiye', school: 'Şeyh Şamil Meslekî ve Teknik Anadolu Lisesi' },
        { id: 2, projectName: 'ALZİMER ERKEN TANI KİTİ OLARAK GİYİLEBİLİR TEKNOLOJİ', school: 'Avcılar Mesleki ve Teknik Anadolu Lisesi' },
        { id: 3, projectName: 'MahalleM', school: 'Şeyh Şamil Mesleki Teknik ve Anadolu Lisesi' },
        { id: 4, projectName: 'Gören Rehberim', school: 'Şeyh Şamil Mesleki Teknik ve Anadolu Lisesi' },
        { id: 5, projectName: 'SÜRDÜRÜLEBİLİRLİK', school: 'İbrahim Özaydın Mesleki ve Teknik Anadolu Lisesi' },
        { id: 6, projectName: 'DAMAR GÖRÜNTÜLEMEDE 3D AKILLI GÖZLÜK', school: 'Avcılar Mesleki ve Teknik Anadolu Lisesi' },
        { id: 7, projectName: 'MINDKEEP', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 8, projectName: 'Formaldehit ölçer ile alerji ve egzamaya son', school: 'Avcılar Mesleki ve Teknik Anadolu Lisesi' },
        { id: 9, projectName: 'Yeşil dost', school: 'Pendik Türk Telekom Mesleki ve Teknik Anadolu Lisesi' },
        { id: 10, projectName: 'Orbital Cleaner', school: 'İstanbul Havalimanı Mesleki ve Teknik Anadolu Lisesi' },
        { id: 11, projectName: 'TÜRKI', school: 'İstanbul Havalimanı Mesleki ve Teknik Anadolu Lisesi' },
        { id: 12, projectName: 'KIT (kendinden ısıtmalı termos)', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 13, projectName: 'ÖĞRENCİ TAKİP SİSTEMİ', school: 'İsov Dinçkök Mesleki ve Teknik Anadolu Lisesi' },
        { id: 14, projectName: 'The Best Recylist', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 15, projectName: 'AKIN-SU', school: 'İstanbul Havalimanı Mesleki ve Teknik Anadolu Lisesi' },
        { id: 16, projectName: 'Senin Kokun', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 17, projectName: 'Geri Dönüşüm Enerji Köyü Projesi', school: 'Pendik Türk Telekom Şehit Murat Mertel Mesleki ve Teknik Anadolu Lisesi' },
        { id: 18, projectName: 'Güneş Paneli İle Çalışan Araba (GÜNPAR)', school: 'İsov Dinçkök Mesleki ve Teknik Anadolu Lisesi' },
        { id: 19, projectName: 'Sisten Suya Yolculuk: Sistematik', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 20, projectName: 'giderayak', school: 'İstanbul Havalimanı Mesleki ve Teknik Anadolu Lisesi' }
      ],
      projectFinalists: [
        { id: 1, projectName: 'ALZİMER ERKEN TANI KİTİ OLARAK GİYİLEBİLİR TEKNOLOJİ', school: 'Avcılar Mesleki ve Teknik Anadolu Lisesi' },
        { id: 2, projectName: 'Güneş Panellerinde Verim Artırma', school: 'Ayhan Arı Borsa İstanbul Mesleki ve Teknik Anadolu Lisesi' },
        { id: 3, projectName: 'DAMAR GÖRÜNTÜLEMEDE 3D AKILLI GÖZLÜK', school: 'Avcılar Mesleki ve Teknik Anadolu Lisesi' },
        { id: 4, projectName: 'MINDKEEP', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 5, projectName: 'Formaldehit ölçer ile alerji ve egzamaya son', school: 'Avcılar Mesleki ve Teknik Anadolu Lisesi' },
        { id: 6, projectName: 'Yeşil dost', school: 'Pendik Türk Telekom Şehit Murat Mertel Mesleki ve Teknik Anadolu Lisesi' },
        { id: 7, projectName: 'KIT (kendinden ısıtmalı termos)', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 8, projectName: 'ÖĞRENCİ TAKİP SİSTEMİ', school: 'İsov Dinçkök Mesleki ve Teknik Anadolu Lisesi' },
        { id: 9, projectName: 'Yükselen Enerji', school: 'İstanbul Havalimanı Mesleki ve Teknik Anadolu Lisesi' },
        { id: 10, projectName: 'The Best Recylist', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 11, projectName: 'AKIN-SU', school: 'İstanbul Havalimanı Mesleki ve Teknik Anadolu Lisesi' },
        { id: 12, projectName: 'Senin Kokun', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 13, projectName: 'Güneş Paneli İle Çalışan Araba (GÜNPAR)', school: 'İsov Dinçkök Mesleki ve Teknik Anadolu Lisesi' },
        { id: 14, projectName: 'Sisten Suya Yolculuk: Sistematik', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 15, projectName: 'Kariyer Rotası', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 16, projectName: 'Otomatik Perde Kontrol Mekanizması', school: 'İsov Dinçkök Mesleki ve Teknik Anadolu Lisesi' },
        { id: 17, projectName: 'ÖKDT (özel kurulum deney tüpü)', school: 'Baykar Milli Teknoloji Mesleki ve Teknik Anadolu Lisesi' },
        { id: 18, projectName: 'Elektrikli Araç Solar Şarj İstasyonu', school: 'İsov Dinçkök Mesleki ve Teknik Anadolu Lisesi' },
        { id: 19, projectName: 'Güneş Enerjisi İle Çalışan İşitme Cihazı Geli', school: 'Haydarpaşa Mesleki ve Teknik Anadolu Lisesi' },
        { id: 20, projectName: 'FOYAK (Fonksiyonel Yaşam Kiti)', school: 'İsov Dinçkök Mesleki ve Teknik Anadolu Lisesi' }
      ]


    };
  }
};
</script>

import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router'

import Home from './views/Home';
import About from './views/About';
import Contact from './views/Contact';
import NotFound from './views/NotFound';
import Partners from './components/Partners';
import BeforePrograms from './components/BeforePrograms'
import CurrentPrograms from './components/CurrentPrograms'

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getPerformance } from "firebase/performance";

Vue.use(VueRouter)

import './scss/main.scss';
import Announcement from "@/components/Announcement.vue";
import FinalistAnnouncement from "@/components/FinalistAnnouncement.vue";
import ProgramFlow from "@/components/ProgramFlow.vue";
import AwardWinners from "@/components/common/AwardWinners.vue";

Vue.config.productionTip = false

const router = new VueRouter({
  routes: [
    { path: '/', component: Home },
    { path: '/hakkimizda', component: About },
    { path: '/basvuru', to: 'https://forms.gle/xdVqLqXk3eKveQ1X8' },
    { path: '/sponsorlarimiz', component: Partners },
    {path: '/duyuru', component: Announcement},
    {path: '/odul-toreni-2024', component: AwardWinners},
    {path: '/finalist-ilani/2024', component: FinalistAnnouncement},
    {path: '/program-akısı-2024', component: ProgramFlow},
    { path: '/birfikirbirproje/2023', component: CurrentPrograms },
    // { path: '/birfikirbirproje/2023-2', component: CurrentPrograms },
    { path: '/oncekiprogramlarimiz/2020-2022', component: BeforePrograms },
    { path: '/oncekiprogramlarimiz/2019', component: BeforePrograms },
    { path: '/oncekiprogramlarimiz/2018', component: BeforePrograms },
    { path: '/oncekiprogramlarimiz/2017', component: BeforePrograms },
    { path: '/iletisim', component: Contact },
    { path: '*', component: NotFound }
  ],
  mode: 'hash'
})



const firebaseConfig = {
  apiKey: "AIzaSyDNuZ66x8vbanalQWoqeKXIlIN1rarMccY",
  authDomain: "f1p-57cb9.firebaseapp.com",
  projectId: "f1p-57cb9",
  storageBucket: "f1p-57cb9.appspot.com",
  messagingSenderId: "903924774901",
  appId: "1:903924774901:web:89372aed9778e7e30bd2bc",
  measurementId: "G-QKRMW15F4M"
};

// Firebase'i başlatın
const app = initializeApp(firebaseConfig);
getAnalytics(app);
getPerformance(app)

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')
